import type React from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
};

function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): React.JSX.Element {
  return <>{condition ? wrapper(children) : children}</>;
}

export default ConditionalWrapper;
